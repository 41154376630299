<template>
  <div class="entertainment">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="enterprise_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="strip">
        <div class="car">
          娱乐设施
        </div>
      </div>
      <div class="stop-box">
        <el-row>
          <ul class="stop-list">
            <li class="stop-item" v-for="item in result" :key="item">
              <el-col :md="6">
                <router-link
                  :to="{ path: '/entertdata', query: { id: item.id } }"
                >
                  <div class="whole">
                    <img :src="`/jeecg-boot/${item.theLocal}`" />
                    <div class="title">
                      <span>{{ item.theTitle }}</span>
                    </div>
                    <span class="xia"> >> </span>
                    <div class="jq">
                      <span class="jq-text"
                        ><i class="el-icon-location"></i>
                        {{ item.theAdd }}</span
                      >
                      <p class="jq-phone">咨询电话：{{ item.theTel }}</p>
                    </div>
                    <div class="xq">
                      <p v-html="item.theDetails"></p>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </li>
          </ul>
        </el-row>
      </div>
      <Pagi
        :total="pagi.total"
        :current="pagi.current"
        :sizes="pagi.size"
        @pagicurrent="togglePagi"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import BannerImage from "@/components/main/BannerImage";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  created() {
    this.loadInfo(1);
  },
  components: {
    Header,
    Footer,
    Headers,
    Pagi,
    Breadcrumb,
    BannerImage,
  },
  data() {
    return {
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      crumbdata: [
        { name: "旅游企业", url: "" },
        { name: "娱乐设施", url: "/entertainment" },
      ],
    };
  },
  methods: {
    loadInfo(value) {
      getAction("/yule/tourYule/list", {
        pageNo: value,
      }).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadInfo(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.entertainment {
  .stop-box {
  }
  .whole {
    width: 267px;

    background-color: #ffffff;
    border: solid 1px #dbdbdb;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 22px;
    img {
      width: 257px;
      height: 194px;
      margin-left: 5px;
      margin-top: 7px;
    }
    .title {
      width: 144px;
      font-size: 18px;
      line-height: 29px;
      color: #333333;
      margin-left: 17px;
      margin-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .xia {
      float: right;
      margin-right: 18px;
      margin-top: -21px;
    }
    .jq {
      margin-left: 17px;
      margin-top: 15px;
      .jq-text {
        width: 67px;
        height: 14px;
        font-size: 14px;
        line-height: 29px;
        color: #6EB400;
      }
      .jq-phone {
        margin-top: 8px;
        font-size: 14px;
        color: #666666;
      }
    }
    .xq {
      margin-left: 17px;
      margin-bottom: 18px;
      max-height: 67px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      p {
        font-size: 14px;
        line-height: 24px;
        color: #666666;
      }
    }
  }
  .whole:hover {
    transition-delay: 0.2s;
    box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
  }
}
.pagi-box {
  text-align: center;
  padding: 30px 0 25px;
}
</style>
